.TipsPage header {
  margin-top: 45px;
  font-size: 16px;
}

.TipsPage h1 {
  margin: 45px 5px 0 5px;
  border-radius: 15px;
  padding-bottom: 0px;
  background-color: rgba(255, 255, 255, .8);
}

.TipsPage h2 {
  background-color: lightblue;
  margin: 20px auto;
  width: 200px;
  border-radius: 10px;
  font-weight: bold;
}

.TipsPage section {
  min-height: 200px;
  padding: 5px;
  background-color: rgb(255, 255, 255);
  margin: 5px;
  border-radius: 5px;
  border: 1px solid grey;
  box-shadow: 3px 3px 6px;
}

.TipsPage button {
  margin-top: 20px;
  margin-bottom: 40px;
}

.TipsPage li {
  margin-bottom: 5px;
}

@media screen and (min-width: 505px) {
  .TipsPage header {
    font-size: 20px;
  }
  .TipsPage h1 {
    margin-left: 10vw;
    margin-right: 10vw;
  }
  .TipsPage section {
    margin-right: 10vw;
    margin-left: 10vw;
  }
  .TipsPage li {
    margin-bottom: 10px;
  }
}

@media screen and (min-width: 808px) {
  
}
