.NotesPage header {
  margin-top: 45px;
  font-size: 16px;
}

.NotesPage h1 {
  margin: 45px 5px 0 5px;
  border-radius: 15px;
  padding-bottom: 0px;
  background-color: rgba(255, 255, 255, .8);
}

.NotesPage h2 {
  background-color: yellow;
  margin: 20px auto;
  width: 200px;
  border-radius: 10px;
  font-weight: bold;
}

.NotesPage .main-page {
  margin-bottom: 40px;
  margin-top: 40px;
}

.NotesPage .note-adder {
  margin-top: 10px;
}

.NotesPage .no-note {
  margin: 5px 10vw;
  background-image: url(../Note/parchment-texture.jpg);
  border-radius: 5px;
  box-shadow: 3px 3px 6px;
}

.NotesPage .edit-buttons {
  display: flex;
  justify-content: space-around;
  margin: 5px 40%;
}

.NotesPage textarea {
  min-width: 40vw;
  min-height: 10vh;
}

@media screen and (min-width: 505px) {
  .NotesPage header {
    font-size: 20px;
  }
  .NotesPage h1 {
    margin-left: 10vw;
    margin-right: 10vw;
  }
  .NotesPage .no-note {
    margin: 40px 30vw;
  }

  .NotesPage .main-page {
    margin-top: 80px;
  }
}

@media screen and (min-width: 808px) {
  .NotesPage textarea {
    min-height: 20vh;
  }
}