.MyGamesPage .GamesListEntry:nth-child(5n) {
  background-color: white;
}
.MyGamesPage .GamesListEntry:nth-child(5n+1) {
  background-color: lightblue;
}
.MyGamesPage .GamesListEntry:nth-child(5n+2) {
  background-color: red;
}
.MyGamesPage .GamesListEntry:nth-child(5n+3) {
  background-color: lightgreen;
}
.MyGamesPage .GamesListEntry:nth-child(5n+4) {
  background-color: yellow;
}

.MyGamesPage header {
  margin: 45px 5px 0 5px;
  border-radius: 15px;
  padding-bottom: 0px;
  font-size: 18px;
  background-color: rgba(255, 255, 255, .8);
}

.MyGamesPage p {
  margin: 5px;
  background-color: rgb(255, 255, 255, .8);
  border-radius: 15px;
  border: 1px solid black;
}

@media screen and (min-width: 505px) {
  .MyGamesPage header {
    width: 500px;
    margin-right: auto;
    margin-left: auto;
  }
  .MyGamesPage p {
    margin: 10vh 20vw;
  }
}

@media screen and (min-width: 808px) { 
}