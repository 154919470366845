.LoginPage header {
  margin: 45px 5px 0 5px;
  padding-bottom: 0px;
  font-size: 16px;
  background-image: url(../../board-background2.jpg);
  border-radius: 15px;
  box-shadow: 3px 3px 6px;
}

.LoginPage h1 {
  border-radius: 15px;
  background-color: rgba(7, 135, 135, .8);
  border: 1px solid black;
}

.LoginPage .input-fields-background {
  background-image: url(../../board-background2.jpg);
  border-radius: 15px;
  margin: 0 5px;
  box-shadow: 3px 3px 6px;
}

.LoginPage .input-fields {
  border-radius: 15px;
  border: 1px solid black;
  padding: 20px;
  background-color: rgb(136, 7, 7, .8);
  color: white;
}

.LoginPage button {
    margin-top: 20px;
}

.LoginPage input {
  margin-left: 5px;
}

.LoginPage .button-box {
  display: flex;
  justify-content: space-around;
}

@media screen and (min-width: 505px) {
  .LoginPage header {
    margin: 45px 20vw 60px 20vw;
  }
  .LoginPage .input-fields-background, header {
    margin-left: 20vw;
    margin-right: 20vw;
  }
  .LoginPage input {
    margin-bottom: 20px;
  }
  .LoginPage .button-box {
    margin-top: 60px;
    margin-right: 30vw;
    margin-left: 30vw;
  }
}
