.SignupPage header {
  margin: 45px 5px 0 5px;
  padding-bottom: 0px;
  font-size: 16px;
  background-image: url(../../board-background2.jpg);
  border-radius: 15px;
}

.SignupPage h1 {
  border-radius: 15px;
  background-color: rgba(7, 135, 135, .9);
  border: 1px solid black;
}

.SignupPage .input-fields-background {
  background-image: url(../../board-background2.jpg);
  border-radius: 15px;
  margin: 0 5px;
}

.SignupPage .input-fields {
  border-radius: 15px;
  border: 1px solid black;
  padding: 20px;
  background-color: rgb(136, 7, 7, .9);
  color: white;
}

.SignupPage button {
    margin-top: 20px;
}

.SignupPage input {
  margin-left: 5px;
}

.SignupPage .button-box {
  display: flex;
  justify-content: space-around;
}

@media screen and (min-width: 505px) {
  .SignupPage header {
    margin: 45px 20vw 60px 20vw;
  }
  .SignupPage .input-fields-background, header {
    margin-left: 20vw;
    margin-right: 20vw;
  }
  .SignupPage input {
    margin-bottom: 20px;
  }
  .SignupPage .button-box {
    margin-top: 60px;
    margin-right: 30vw;
    margin-left: 30vw;
  }
}

