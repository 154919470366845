.GamesListEntry {
  margin: 0px 20px 20px 20px;
  padding: 10px;
  border-radius: 10px;
  border: solid black 1px;
  box-shadow: 3px 3px 6px;
}

.GamesListEntry ul {
  list-style: none;
  padding-left: 0px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  margin: 0 0 5px 0;
  font-size: 85%;
}

.GamesListEntry li {
  margin-right: 10px;
}

.GamesListEntry h2 {
  margin: 0 0 0 10px;
}

.GamesListEntry a {
  text-decoration: none;
  color: black;
}

.GamesListEntry .game-name {
  display: flex;
  align-items: center;
  justify-content: center;
}

.GamesListEntry img:hover {
  transform: scale(1.1);
}

.GamesListEntry h2:hover {
  transform: scale(1.1);
}

@media screen and (min-width: 505px) {
  .GamesListEntry {
    margin-left: 5vw;
    margin-right: 5vw;
  }
}

@media screen and (min-width: 808px) {
  .GamesListEntry {
    margin-left: 10vw;
    margin-right: 10vw;
    padding: 10px 40px;
  }
  .GamesListEntry .short_description {
    margin-top: 10px;
  }
}