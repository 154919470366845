.RulesPage header {
  margin-top: 45px;
  font-size: 16px;
}

.RulesPage h1 {
  margin: 45px 5px 0 5px;
  border-radius: 15px;
  padding-bottom: 0px;
  background-color: rgba(255, 255, 255, .8);
}

.RulesPage h2 {
  background-color: #ff0000;
  margin: 20px auto;
  width: 200px;
  border-radius: 10px;
  font-weight: bold;
}

/* .RulesPage canvas {
  margin-right: auto;
  margin-left: auto;
} */

.RulesPage iframe {
  display: block;
  width: 95vw;
  height: 50vh;
  margin-right: auto;
  margin-left: auto;
}

.RulesPage a {
  text-decoration: none;
  color: black;
}

.RulesPage .pdf-link {
  padding: 10px;
  border-radius: 5px;
  background-color: rgba(7, 135, 135, .8);
  display: block;
  margin-left: auto;
  margin-right: auto;
  max-width: 90%;
  margin-top: 5px;
}

.RulesPage .pdf-ling:hover {
  transform: none;
}

.RulesPage .main-page {
  margin-top: 20px;
  margin-bottom: 40px;
}

@media screen and (min-width: 505px) {
  .RulesPage iframe {
    height: 80vh;
  }
  .RulesPage header {
    font-size: 20px;
  }
  .RulesPage h1 {
    margin-left: 10vw;
    margin-right: 10vw;
  }
}

@media screen and (min-width: 808px) {
  .RulesPage iframe {
    height: 90vh;
  }
}