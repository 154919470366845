.Note {
  background-image: url(./parchment-texture.jpg);
  margin: 10px;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 3px 3px 6px;
}

.Note .container {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.Note .title {
  width: 100%
}

.Note button {
  background-color: rgba(255, 255, 255, .5);
  border: 1px solid black;
  border-radius: 15px;
  font-size: 20px;
  margin: 5px;
}

.Note .expand {
  width: 30px;
  height: 30px;
  margin: 0;
}

.Note .expand:hover {
  background-color: white;
}

.Note .edit-buttons {
  display: flex;
  justify-content: space-around;
  margin: 5px 30%;
}

.Note textarea {
  min-width: 40vw;
  min-height: 10vh;
}

@media screen and (min-width: 505px) {
  .Note {
    margin: 20px 10vw;
  }
}

@media screen and (min-width: 808px) {
  .Note {
    margin: 20px 20vw;
  }
  .Note textarea {
    min-height: 20vh;
  }
}