.NavBar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;
  padding: 1% 5px;
  background-color: #87ADDE;
  display: flex;
  justify-content: space-between;
  font-size: 18px;
  max-width: 100vw;
}

.NavBar a {
  color: black;
  text-decoration: none;
}

.NavBar button {
  padding: 0;
  background-color: #87ADDE;
  border: none;
}

@media screen and (min-width: 505px) {
  .NavBar {
    padding: 5px 5vw;
    font-size: 20px;
  }
}

@media screen and (min-width: 808px) {
  
}