.GamePage header {
  margin: 45px 5px 0 5px;
  border-radius: 15px;
  padding-bottom: 0px;
  font-size: 16px;
  background-color: rgba(255, 255, 255, .8);
}

.GamePage h1 {
  font-weight: bold;
}

.GamePage ul {
  padding-left: 0px;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  list-style: none;
  margin-bottom: 0;
  margin-top: 5px;
}

.GamePage li {
  margin-right: 5px;
}

.GamePage .rating-genres, .description {
  border-radius: 5px;
  border: solid black 2px;
  padding: 5px;
}

.GamePage .description-background, .rating-genres-background {
  background-image: url(../../board-background2.jpg);
  margin: 0 5px;
  border-radius: 5px;
  box-shadow: 3px 3px 6px;
}

.GamePage .rating-genres {
  color: white;
  background-color: rgb(136, 7, 7, .8);
}

.GamePage .description {
  background-color: rgb(8, 166, 166, .8);
  margin-top: 10px;
}

.GamePage button {
  background-color: white;
  border: 1px solid black;
  border-radius: 15px;
  font-size: 20px;
}

.GamePage .button-box {
  margin-top: 30px;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-around;
}

.GamePage .add-remove-button {
  width: 200px;
  border-radius: 5px;
  color: white;
  background-color: black;
  margin-bottom: 40px;
}

@media screen and (min-width: 505px) {
  .GamePage .add-remove-button {
    margin-top: 40px;
    width: 300px;
    font-size: 1.1em;
    padding: 10px;
  }
  .GamePage .rating-genres-background, .buttons {
    margin-top: 40px;
    margin-bottom: 40px;
  }
  .GamePage header, .rating-genres-background, .description-background {
    margin-right: 10vw !important;
    margin-left: 10vw !important;
  }
  .GamePage .buttons {
    margin-right: 10vw;
    margin-left: 10vw;
  }
}

@media screen and (min-width: 808px) {
  
}