.Footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 30px;
  display: flex;
  justify-content: space-between;
  padding: 1% 5px 3% 5px;
  background-color: #87ADDE;
  font-size: 18px;
  max-width: 100vw;
}

.Footer a {
  color: black;
  text-decoration: none;
}

.Footer button {
  padding: 0;
  background-color: #87ADDE;
  border: none;
}

@media screen and (min-width: 505px) {
  .Footer {
    padding: 5px 5vw 30px 5vw;
    font-size: 20px;
  }
}

@media screen and (min-width: 808px) {
  .Footer {
    padding-bottom: 30px;
  }
}